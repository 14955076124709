import { apPy, apPySharpies, backend } from "System/system";
import store from "System/mainStore";
// -------------------------------------------------------
import { attachDir } from "Redux/files/actions";

// -------------------------------------------------------
// -------------------------------------------------------
const getUpdatedDirs = async (path) => {
  const data = store.getState();
  const { converters } = data.conversions;
  const extensions = Object.keys(converters);
  const result = await backend("files", "getDir", { path, tag: "scan" });
  if (!result.data) return null;
  const { flatFiles } = result.data;
  const files = { all: flatFiles };
  extensions.forEach((ext) => {
    files[ext] = flatFiles.filter((x) => x.ext === ext.toUpperCase());
  });
  return files;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const startUnzipping =
  (startDir, leadingZeros, tag) => async (dispatch) => {
    let files;
    const data = store.getState();
    const { converters } = data.conversions;
    const extensions = Object.keys(converters);
    dispatch({ type: "CONVERTERS_COMPLETE_STEP", ext: "upload" });

    dispatch({ type: "SET_CHECKIN_PROGRESS", ext: "traverse" });
    dispatch({ type: "SET_SCANNING", scanning: true });
    files = await getUpdatedDirs(startDir);
    dispatch({ type: "SET_SCANNING", scanning: false });
    dispatch({ type: "CONVERTERS_COMPLETE_STEP", ext: "traverse" });

    dispatch(updateConversionStats(files, extensions));
    dispatch({ type: "SET_CHECKIN_PROGRESS", ext: "zip" });
    await dispatch(unzipAll(files["all"]));
    dispatch({ type: "CONVERTERS_COMPLETE_STEP", ext: "zip" });

    const converterFuncs = {
      xlsm: xlsm2xlsx,
      tab: tab2csv,
      dat: dat2csv,
      txt: txt2csv,
      tsv: txt2csv,
      ssv: ssv2csv,
      xls: xlsx2csv,
      xlsx: xlsx2csv,
      pdf: pdf2csv,
      prsfw: prsfw2csv,
      socan: socan2csv,
      welk: welk2csv,
    };

    dispatch({ type: "SET_SCANNING", scanning: true });
    files = await getUpdatedDirs(startDir);
    dispatch({ type: "SET_SCANNING", scanning: false });
    dispatch(updateConversionStats(files, extensions));

    const prsfwFiles = files.prsfw;
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    for (var cc = 0; cc < prsfwFiles.length; cc++) {
      dispatch({
        type: "SET_CONVERSION_PROGRESS",
        pointer: cc,
        ext: "prshomog",
      });
      //await sleep(2000);
      await homogenizePrsfw(prsfwFiles[cc]);
    }

    dispatch({ type: "SET_SCANNING", scanning: true });
    files = await getUpdatedDirs(startDir);
    dispatch({ type: "SET_SCANNING", scanning: false });
    dispatch(updateConversionStats(files, extensions));

    for (var ii = 3; ii < extensions.length - 2; ii++) {
      const thisExtension = extensions[ii];
      console.log("thisExtension", thisExtension);
      const theseFiles = files[thisExtension];
      console.log(thisExtension, theseFiles);
      for (var cc = 0; cc < theseFiles.length; cc++) {
        dispatch({
          type: "SET_CONVERSION_PROGRESS",
          pointer: cc,
          ext: thisExtension,
        });
        const thisFile = theseFiles[cc];
        await converterFuncs[thisExtension](thisFile);
      }
      dispatch({ type: "CONVERTERS_COMPLETE_STEP", ext: thisExtension });

      dispatch({ type: "SET_SCANNING", scanning: true });
      files = await getUpdatedDirs(startDir);
      dispatch({ type: "SET_SCANNING", scanning: false });
      dispatch(updateConversionStats(files, extensions));
    }

    const csvFiles = files.csv;
    for (var cc = 0; cc < csvFiles.length; cc++) {
      dispatch({
        type: "SET_CONVERSION_PROGRESS",
        pointer: cc,
        ext: "csv",
      });
      await encodeUtf8(csvFiles[cc]);
    }

    if (leadingZeros) {
      for (var cc = 0; cc < csvFiles.length; cc++) {
        dispatch({
          type: "SET_CONVERSION_PROGRESS",
          pointer: cc,
          ext: "str",
        });
        await stripLeadingZeros(csvFiles[cc]);
      }
    }

    dispatch(attachDir(startDir, tag));
    dispatch({ type: "SHOW_HIDE_CONVERTERS", showConverters: false });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const showHideConverters = (showConverters) => ({
  type: "SHOW_HIDE_CONVERTERS",
  showConverters,
});

// -------------------------------------------------------
// -------------------------------------------------------
const updateConversionStats = (files, extensions) => (dispatch) => {
  const counts = {};

  for (var ii = 0; ii < extensions.length; ii++) {
    const thisExtension = extensions[ii];
    counts[thisExtension] = files[thisExtension].length;
  }
  dispatch({ type: "SET_CONVERSION_STATS", counts });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const unzipAll = (flatFiles) => async (dispatch) => {
  const zipFiles = flatFiles.filter((x) => x.ext === "ZIP");
  dispatch({ type: "SET_ZIP_STATS", nZips: zipFiles.length });

  const newZips = [];
  let pointer = 0;
  for (var zipFile of zipFiles) {
    pointer++;
    dispatch({
      type: "SET_CONVERSION_PROGRESS",
      pointer,
      ext: "zip",
    });

    const result = await backend("files", "unzip", zipFile);
    const { data } = result;
    const remainingZipFiles = data.flatFiles.filter((x) => x.ext === "ZIP");
    if (remainingZipFiles.length) {
      for (var newZipFile of remainingZipFiles) {
        newZips.push(newZipFile);
      }
    }
  }
  if (newZips.length) {
    await dispatch(unzipAll(newZips));
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const xls2xlsx = async (file) => {
  await backend("files", "xls2xlsx", {
    xlsFile: file.name,
    xlsxFile: file.name.replace(/\./g, "_") + ".xlsx",
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const xlsm2xlsx = async (file) => {
  await backend("files", "xlsm2xlsx", {
    xlsmFile: file.name,
    xlsxFile: file.name.replace(/\./g, "_") + ".xlsx",
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const tab2csv = async (file) => {
  await backend("files", "tab2csv", {
    tabFile: file.name,
    csvFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const dat2csv = async (file) => {
  await apPy("files", "dat2csv", {
    datFile: file.name,
    csvFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const pdf2csv = async (file) => {
  const path_pdf = `${file.path}/${file.name}`;
  const path_csv = `${file.path}/${file.name.replace(/\./g, "_") + ".csv"}`;
  await apPySharpies("parse", { path_pdf, path_csv });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const txt2csv = async (file) => {
  await apPy("files", "txt2csv", {
    txtFile: file.name,
    csvFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const ssv2csv = async (file) => {
  console.log("ssv2csv", file);
  await apPy("files", "ssv2csv", {
    ssvFile: file.name,
    csvFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};
// -------------------------------------------------------
// -------------------------------------------------------
export const xlsx2csv = async (thisFile) => {
  const isLocal = window.location.href.slice(7, 16) === "localhost";
  let file = thisFile.name;
  let path = thisFile.path;
  await backend("files", "xlsx2csvLibre", { file, path, isLocal });
  await backend("files", "eraseFile", { file, path });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const encodeUtf8 = async (file) => {
  let csvfile = file.name;
  let path = file.path;
  await backend("files", "encodeUtf8", { csvfile, path });
};

// -------------------------------------------------------
// -------------------------------------------------------
const homogenizePrsfw = async (file) => {
  const prsfwFile = file.name;
  const path = file.path.replace(/\./g, "");
  await apPy("files", "homogenize_prsfw", {
    path,
    prsfwFile,
  });
  await backend("files", "eraseFile", {
    file: prsfwFile,
    path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const stripLeadingZeros = async (file) => {
  console.log("stripLeadingZeros", file);
  await apPy("files", "strip_leading_zeros", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const socan2csv = async (file) => {
  await apPy("files", "socan2csv", {
    fromFile: file.name,
    toFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const prsfw2csv = async (file) => {
  await apPy("files", "prsfw2csv", {
    fromFile: file.name,
    toFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const welk2csv = async (file) => {
  await apPy("files", "welk2csv", {
    fromFile: file.name,
    toFile: file.name.replace(/\./g, "_") + ".csv",
    path: file.path.replace(/\./g, ""),
  });
  await backend("files", "eraseFile", {
    file: file.name,
    path: file.path,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const hipgnosify = (file, parser) => async (dispatch) => {
  await apPy("files", "hipgnosify", {
    file: file.name,
    path: file.path.replace(/\./g, ""),
    cut: file.cut,
    parser,
  });
};
